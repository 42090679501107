import React from 'react'
import { Parallax } from 'react-parallax';
import { graphql, useStaticQuery } from 'gatsby';


const AboutSubsection = ({id, title, bullets}) => {
    return (
        <div className="about-outline subsection-about">
            <ul className="about-list">
                {bullets.map(aboutItem => 
                    <li key={aboutItem} 
                        dangerouslySetInnerHTML={{__html: aboutItem}}
                        className="about-list-item">
                    </li>
                )}
            </ul> 
        </div>
    )
}

const About = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allAboutYaml {
                    edges {
                        node {
                            id
                            title
                            bullets        
                        }
                    }
                }
            }
        `
    )

    return (
        <section className="about-section" id="about">
            <Parallax strength={500} className="parallax-background" bgImageAlt="Hello" bgImageStyle={{opacity: 0.8}} bgImage={require('./media/sfpano.jpg')}>
                <div className="about-container">
                    <img className="headshot-img" src={require('./media/headshot.jpg')} alt="headshot"></img>
                    <h2 className="about-outline light-title">About Me</h2>
                    {data.allAboutYaml.edges.map(aboutInfo =>
                        <AboutSubsection key={aboutInfo.node.id} {...aboutInfo.node} />    
                    )}
                </div>
            </Parallax>
        </section>
    )
}

export default About;