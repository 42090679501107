import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import Project from './project';
import '../styles/projects.css';


export default function ProjectContainer() {
    const data = useStaticQuery(
        graphql`
            query {
                allProjectsYaml {
                    edges {
                        node {
                            id
                            title
                            imgurl
                            srcurl
                            demoInternal
                            demoLink
                            demoIcon
                            demoName
                            technologies
                            description
                            role
                        }  
                    }
                }
            }
        `
    )

    return (
        <>
        <div className="page-grid" id="projects">
            <div className="section__projects">
                <div className="title__container">
                    <h2 className="section__title section__title--project__title">Projects
                        <span className="section__subtitle--icon"><i className="fas fa-laptop-code fa-sm"></i></span>
                    </h2>
                </div>
                <div className="projects__container">
                    {data.allProjectsYaml.edges.map((project) => 
                        <Project key={project.node.id} {...project.node}/>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}
