import React, { useEffect, useState, useLayoutEffect } from "react"
import "../styles/global.css"


const Cover = () => {
    const [typingText, updateTypingText] = useState("");
    const [typeIndex, updateTypeIndex] = useState(0);
    const [doneTyping, updateDoneTyping] = useState(false);

    useLayoutEffect(() => {
        const typeWord = "Software Developer";
        if (typeIndex < typeWord.length) {
            setTimeout(() => {
                updateTypingText(typingText => typingText+typeWord[typeIndex]);
                updateTypeIndex(typeIndex => typeIndex+1);
            }, 120)
        }
        else {
            updateDoneTyping(true);
        }
    }, [typeIndex]);

    return (
    <section className="intro" id="home">
        <h1 className="section__title section__title--intro"> 
          <strong>Evan Johnson</strong>
        </h1>  
        <p className="section__subtitle section__subtitle--intro"><span className="typing-text"><span className={doneTyping ? "txt cursor-blinking" : "txt"}>{typingText}</span></span></p>
      </section>
    )
}

export default Cover;