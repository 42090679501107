import React from 'react'

export default function Project({title, imgurl, srcurl, demoInternal, demoLink, demoIcon, demoName, technologies, description, role}) {
    return (
        <>
            <div className="project" data-aos="fade-right">
                <div className="column-1">
                    <h2 className="project-title">{title}</h2>
                    <div className="project__display">
                        <img src={require('./media/'+imgurl)} alt="thumbnail"/>
                    </div>
                    <div className="demo__links">
                    <a href={srcurl} className="demo__link"><i className="fab fa-github-alt demo__icon"></i>Source Code</a>
                    <a href={demoInternal=='yes'?require("./media/"+demoLink):demoLink} className="demo__link"><i className={demoIcon+" demo__icon"}></i>{demoName}</a>
                    </div>
                    <h3 className="small__title">Technologies Used</h3>
                    <ul className="technologies">
                        {technologies.map((tech) => 
                            <li key={tech}><span><i className="fas fa-dot-circle bullet"></i>{tech}</span></li>
                        )}
                    </ul>
                </div>
                <div className="column-2">
                    <div className="project__detail">
                        <h3 className="small__title">Description</h3>
                        <p>{description}</p>
                    </div>
                    <div className="project__detail">
                        <h3 className="small__title">My Role</h3>
                        <p>{role}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
