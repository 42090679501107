import React, {useState, useLayoutEffect} from 'react'
import { useStaticQuery, graphql } from 'gatsby';


const Skill = ({id, title, animation, img, subtitle, description}) => {
    const [hidden, updateHidden] = useState(false);
    const [expanded, updateExpanded] = useState(false);

    useLayoutEffect(() => {
        checkResize();
        window.addEventListener('resize', () => checkResize());
    }, []);

    const checkResize = () => {
        const update = window.innerWidth < 1100;
        updateHidden(update);
    }

    const expandDescription = () => {
        updateExpanded(expanded => !expanded);
    }

    return (
        <div className="skill" data-aos={animation}>
            <img className="skill-icon" src={require('./media/'+img)} alt="skill"></img>
            <h3 className="skill-title">{title}</h3>
            <strong>{subtitle}</strong>
            {hidden &&
                <a onClick={() => expandDescription()} className="expand-link">
                    {expanded ? "Less" : "More"}<i className={"fa fa-caret-"+(expanded?"up":"down")+" drop"} aria-hidden="true"></i>
                </a> 
            }
            {(!hidden || expanded) && 
                <p className="skill-description" dangerouslySetInnerHTML={{__html: description}}/>
            }
        </div>    
    )
}

const Skills = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allSkillsYaml {
                    edges {
                        node {
                            id
                            title
                            animation
                            img
                            subtitle
                            description
                        }  
                    }
                }
            }
        `
    )

    return (
        <section className="my-skills" id="skills">
            <h2 className="section__title section__title--skills">Skills and Experience</h2>
                <div className="skills">
                    {data.allSkillsYaml.edges.map(skill => 
                        <Skill key={skill.node.id} {...skill.node}/>
                    )}
                </div> 
        </section>
    )
}

export default Skills;