import React, { useEffect } from "react"
import AOS from 'aos';

import Layout from "../components/layout"
import Cover from '../components/cover'
import Skills from '../components/skills';
import ProjectContainer from '../components/projectContainer';
import About from '../components/about';
import SEO from "../components/seo"
import "../styles/global.css"
import '../../node_modules/aos/dist/aos.css'


const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 500
    });

  }, []);

  return (
    <Layout>
      <SEO title="Evan Johnson" />
      <Cover/>
      <About/>
      <Skills/>
      <ProjectContainer/>
    </Layout>
  )
}

export default IndexPage